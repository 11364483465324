import React from "react";
import Routes from "./Routes";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();
  const TRACKING_ID = "G-SVC6THKQC1"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  React.useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
  return <Routes />;
}

export default App;
