import React from "react";
import styles from "./styles.module.css";
import commingSoonImg from "../../assets/images/commingSoonImg.svg";
// import { TextField } from "@mui/material";
import faceBook from "../../assets/images/Facebook.svg";
import insta from "../../assets/images/Instagram.svg";
import git from "../../assets/images/Github.svg";
import linkedin from "../../assets/images/Linkedin.svg";

const CommingSoonPage = () => {
  const rendertitleView = () => {
    return (
      <div className={styles.soonContainerStyle}>
        <div className={styles.titleWrapperStyle}>
          <p className={styles.titleStyle}>Comming Soon</p>
        </div>
        <div className={styles.descriptionWrapperStyle}>
          <p className={styles.descriptionStyle}>
            or visit <a href="https://viljetech.com/">viljetech.com</a>
          </p>
        </div>
      </div>
    );
  };
  //   const renderInputTextView = () => {
  //     return (
  //       <div className={styles.inputWrapperStyle}>
  //         <TextField
  //           className={styles.inputFieldStyle}
  //           id="outlined-required"
  //           label="Email"
  //           type="text"
  //           InputLabelProps={{
  //             shrink: true,
  //             classes: { root: styles.inputLabelStyles },
  //           }}
  //         />
  //       </div>
  //     );
  //   };
  const renderButtonView = () => {
    return (
      <div className={styles.inputWrapperStyle}>
        <button className={styles.buttonStyle}>Prenumerera</button>
      </div>
    );
  };
  const renderSocialIconsView = () => {
    return (
      <div className={styles.socialContainer}>
        <div className={styles.socialIconsWrapperStyle}>
          <p className={styles.textSocialStyle}>Följ oss på</p>
          <div className={styles.socialImgWrapperStyle}>
            <img src={faceBook} className={styles.imgStyle} />
          </div>
          <div className={styles.socialImgWrapperStyle}>
            <img src={insta} className={styles.imgStyle} />
          </div>
          <div className={styles.socialImgWrapperStyle}>
            <img src={linkedin} className={styles.imgStyle} />
          </div>
          <div className={styles.socialImgWrapperStyle}>
            <img src={git} className={styles.imgStyle} />
          </div>
        </div>
      </div>
    );
  };
  const renderCopyRightsView = () => {
    return (
      <div>
        <p className={styles.textCopyRightsStyle}>
          © Copyrights Vilje Tech AB | All Rights Reserved
        </p>
      </div>
    );
  };
  const renderCommingSoonPage = () => {
    return (
      <div className={styles.mainContainer}>
        <div>
          <div className={styles.leftInsideWrapperStyle}>
            {rendertitleView()}
            {/* {renderButtonView()} */}
            {/* {renderSocialIconsView()}
            {renderCopyRightsView()} */}
          </div>
        </div>
        <div className={styles.imageDisplayStyle}>
          <img src={commingSoonImg} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  return <div className={styles.container}>{renderCommingSoonPage()}</div>;
};

export default CommingSoonPage;
